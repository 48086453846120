import React from 'react'

const CustomModal = ({ children, onClose, isOpen }) => {
  if (isOpen) {
    return (
      <div className="modal-container" onClick={() => onClose()}>
        <div className="close-modal"><span className="close-modal-button">+</span></div>
        <div className="modal-contents">
          {children}
        </div>
      </div>
    )
  }
  return null;
}

export default CustomModal;